import React from 'react';

const FooterScripts = () => {
  return (
    <>
      <script
        id="dsq-count-scr"
        src="//joshwhatk.disqus.com/count.js"
        async
      ></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `
            (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
            })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

            ga('create', 'UA-34686673-3', 'auto');
            ga('send', 'pageview');`,
        }}
      ></script>
    </>
  );
};

export default FooterScripts;
