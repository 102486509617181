import React from 'react';
import { Location } from '@reach/router';
import { withPrefix } from 'gatsby';
import Helmet from 'react-helmet';
import { SITE_URL } from '../../helpers/constants';

const BasicMetadata = ({ title, description }) => {
  return (
    <Location>
      {({ location }) => (
        <Helmet>
          <html lang="en" />
          <title>{title}</title>
          <meta name="description" content={description} />
          <link
            rel="manifest"
            href={`${withPrefix('/')}img/favicon/manifest.webmanifest`}
          />
          <link
            rel="shortcut icon"
            href={`${withPrefix('/')}img/favicon/favicon.ico?v=1`}
          />
          <meta name="theme-color" content="#343f44"></meta>
          <link
            rel="apple-touch-icon"
            sizes="48x48"
            href={`${withPrefix('/')}img/favicon/icon-48x48.png?v=1`}
          />
          <link
            rel="apple-touch-icon"
            sizes="72x72"
            href={`${withPrefix('/')}img/favicon/icon-72x72.png?v=1`}
          />
          <link
            rel="apple-touch-icon"
            sizes="96x96"
            href={`${withPrefix('/')}img/favicon/icon-96x96.png?v=1`}
          />
          <link
            rel="apple-touch-icon"
            sizes="144x144"
            href={`${withPrefix('/')}img/favicon/icon-144x144.png?v=1`}
          />
          <link
            rel="apple-touch-icon"
            sizes="192x192"
            href={`${withPrefix('/')}img/favicon/icon-192x192.png?v=1`}
          />
          <link
            rel="apple-touch-icon"
            sizes="256x256"
            href={`${withPrefix('/')}img/favicon/icon-256x256.png?v=1`}
          />
          <link
            rel="apple-touch-icon"
            sizes="384x384"
            href={`${withPrefix('/')}img/favicon/icon-384x384.png?v=1`}
          />
          <link
            rel="apple-touch-icon"
            sizes="512x512"
            href={`${withPrefix('/')}img/favicon/icon-512x512.png?v=1`}
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="//fonts.googleapis.com/css?family=Merriweather:300,700,700italic,300italic|Open+Sans:700,400"
          />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={title} />
          <meta
            property="og:image"
            content={`${withPrefix('/')}img/og-image.jpg`}
          />
          <meta property="og:url" content={`${SITE_URL}${location.pathname}`} />
          <link rel="canonical" href={`${SITE_URL}${location.pathname}`} />
        </Helmet>
      )}
    </Location>
  );
};

export default BasicMetadata;
