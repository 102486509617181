import React, { useState } from 'react';
import { navigate } from 'gatsby-link';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import useSiteMetadata from '../../components/SiteMetadata';
import BasicMetadata from '../../components/Layout/BasicMetadata';
import FooterScripts from '../../components/Layout/FooterScripts';
import '../../sass/ghost.scss';

const SubscribePage = ({ siteTitle }) => {
  function encode(data) {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&');
  }

  // eslint-disable-next-line
  const [isValidated, setValidatedState] = useState(false);
  const [formFields, setFormFields] = useState({});

  const handleChange = (e) => {
    const fields = formFields;
    fields[e.target.name] = e.target.value;
    setFormFields(fields);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...formFields,
        isValidated,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error));
  };

  return (
    <>
      <Helmet>
        <title>Subscribe | {siteTitle}</title>
        <html className="ghost-cms" />
      </Helmet>
      <div className="gh-app">
        <div className="gh-viewport">
          <main className="gh-main" role="main">
            <div className="gh-flow">
              <header className="gh-flow-head">
                <nav className="gh-flow-nav">
                  <Link to="/" className="gh-flow-back">
                    <i className="icon-arrow-left"></i> Back
                  </Link>
                </nav>
              </header>

              <div className="gh-flow-content-wrap">
                <section className="gh-flow-content">
                  <header>
                    <h1>Subscribe to {siteTitle}</h1>
                  </header>
                  <form
                    name="subscribe"
                    method="post"
                    action="/subscribe/thank-you/"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={handleSubmit}
                    className="gh-signin"
                  >
                    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                    <input type="hidden" name="form-name" value="subscribe" />
                    <div hidden>
                      <label>
                        Don’t fill this out:{' '}
                        <input name="bot-field" onChange={handleChange} />
                      </label>
                    </div>
                    <div className="form-group">
                      <input
                        className="gh-input"
                        type="email"
                        name="email"
                        onChange={handleChange}
                        id="email"
                        required={true}
                        placeholder="Your email address"
                        autoFocus={true}
                      />
                    </div>
                    <button className="btn btn-blue btn-block" type="submit">
                      Send
                    </button>
                  </form>
                </section>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

SubscribePage.propTypes = {
  siteTitle: PropTypes.string.isRequired,
};

const Subscribe = () => {
  const siteMeta = useSiteMetadata();
  console.log(siteMeta);

  return (
    <>
      <BasicMetadata
        title={siteMeta.title}
        description={siteMeta.description}
      />
      <SubscribePage siteTitle={siteMeta.title} />
      <FooterScripts />
    </>
  );
};

export default Subscribe;
